import React from "react"
import { Layout } from "../layouts/Site"
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome"
import { faDirections } from "@fortawesome/pro-light-svg-icons"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"

export default function RedirectTemplate({ pageContext }) {
  return <Layout title={`Xodiac`}>
    <Helmet>
      <meta httpEquiv='refresh' content={`0;url=${pageContext.redirect}`} />
    </Helmet>
    <div className='text-center h-full p-4 mt-20'>
      <h1><Icon icon={faDirections} /> We moved...</h1>
      Sorry, but the article you were looking for is no longer available at this location.<br />
      We will take you to <Link to={pageContext.redirect}>{pageContext.redirect}</Link> momentarily.
    </div>
  </Layout>
}